import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { getImage, GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/Layout'

// eslint-disable-next-line
export const ArticlePageTemplate = ({
  image,
  title,
  date,
  article_content,
}) => {
  const articleParagraphs = []

  for (let i = 0; i < article_content.length; i++) {
    articleParagraphs.push(
      <p key={`articleParagraph-${i}`}>{article_content[i]}</p>,
    )
  }

  const img = getImage(image) || image
  return (
    <div className="container content-container">
      {img.url ? (
        <img src={img} className="article-image" alt={''} />
      ) : (
        <GatsbyImage image={img} className="article-image" alt={''} />
      )}
      <h2>{title}</h2>
      <p>{date}</p>
      {articleParagraphs}
    </div>
  )
}

ArticlePageTemplate.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  article_content: PropTypes.array.isRequired,
}

const ArticlePage = ({ data, pageContext }) => {
  const { markdownRemark } = data
  const { image, title, date, article_content } = markdownRemark.frontmatter

  return (
    <Layout pageContext={pageContext}>
      <ArticlePageTemplate
        image={image}
        title={title}
        date={date}
        article_content={article_content}
      />
    </Layout>
  )
}

ArticlePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape(ArticlePageTemplate.propTypes).isRequired,
    }),
  }),
  pageContext: PropTypes.object.isRequired,
}

export default ArticlePage

export const articlePageQuery = graphql`
  query($id: String!, $lang: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        title
        date(formatString: "DD. MMMM YYYY", locale: $lang)
        article_content
      }
    }
  }
`
